import { t } from 'i18next';
import { useMemo } from 'react';
import { useRole } from '../hooks';

export enum ROLE {
    UNKNOWN = 'unknown',
    PARTNER_OWNER = 'partner_owner',
    PARTNER_ADMIN = 'partner_administrator',
    SITE_ADMIN = 'site_administrator',
    SITE_MODERATOR = 'site_moderator',
    SITE_AUDITOR = 'site_auditor',
    PROVIDER_OWNER = 'provider_owner',
    PROVIDER_ADMIN = 'provider_administrator',
    PROVIDER_CONTENT_MANAGER = 'provider_content_manager',
    PROVIDER_AUDITOR = 'provider_auditor',
    PROVIDER_ENGINEER = 'provider_engineer',
    CONSUMER_OWNER = 'consumer_owner',
    CONSUMER_ADMIN = 'consumer_administrator',
    CONSUMER_CONTENT_MANAGER = 'consumer_content_manager',
    CONSUMER_AUDITOR = 'consumer_auditor',
    CONSUMER_ENGINEER = 'consumer_engineer',
    PARTNER_MODERATOR_OWNER = 'partner_moderator_owner',
    PARTNER_MODERATOR_ADMIN = 'partner_moderator_administrator',
    PARTNER_MODERATOR_MODERATOR = 'partner_moderator_moderator',
    PARTNER_TVSRC_OWNER = 'partner_tvsrc_owner',
    PARTNER_TVSRC_ADMIN = 'partner_tvsrc_administrator',
    PARTNER_TVSRC_MDLG_OWNER = 'partner_tvsrc_mdlg_owner',
    PARTNER_TVSRC_MDLG_ADMIN = 'partner_tvsrc_mdlg_administrator',
}

export enum HISTORY_ROLE {
    CONSUMER = 'consumer',
    PROVIDER = 'provider',
    ADMIN = 'administrator',
    MODERATOR = 'moderator',
    UNKNOWN = 'unknown'
}

export function isConsumer(role: ROLE) {
    const result = (
        role === ROLE.CONSUMER_OWNER
        || role === ROLE.CONSUMER_ADMIN
        || role === ROLE.CONSUMER_AUDITOR
        || role === ROLE.CONSUMER_CONTENT_MANAGER
        || role === ROLE.CONSUMER_ENGINEER
        || role === ROLE.PARTNER_TVSRC_OWNER
        || role === ROLE.PARTNER_TVSRC_ADMIN
        || role === ROLE.PARTNER_TVSRC_MDLG_OWNER
        || role === ROLE.PARTNER_TVSRC_MDLG_ADMIN
    )
    return result;
}

export function isProvider(role: ROLE) {
    const result = (role === ROLE.PROVIDER_OWNER || role === ROLE.PROVIDER_ADMIN
        || role === ROLE.PROVIDER_AUDITOR || role === ROLE.PROVIDER_CONTENT_MANAGER
        || role === ROLE.PROVIDER_ENGINEER)
    return result;
}

export function useIsConsumer() {
    const role = useRole();

    return useMemo(() => [ROLE.CONSUMER_OWNER, ROLE.CONSUMER_AUDITOR, ROLE.CONSUMER_CONTENT_MANAGER, ROLE.CONSUMER_ENGINEER, ROLE.CONSUMER_ADMIN].includes(role.label), [role]);
}

export function useIsProvider() {
    const role = useRole();

    return useMemo(() => [ROLE.PROVIDER_ADMIN, ROLE.PROVIDER_AUDITOR, ROLE.PROVIDER_CONTENT_MANAGER, ROLE.PROVIDER_ENGINEER, ROLE.PROVIDER_OWNER].includes(role.label), [role]);
}

export function useIsAdmin() {
    const role = useRole();

    return useMemo(() => role.label === ROLE.SITE_ADMIN, [role]);
}

export function useIsPartnerSource() {
    const role = useRole();

    return [ROLE.PARTNER_TVSRC_OWNER, ROLE.PARTNER_TVSRC_ADMIN, ROLE.PARTNER_TVSRC_MDLG_OWNER, ROLE.PARTNER_TVSRC_MDLG_ADMIN].includes(role.label);
}

export function useIsMedialogistic() {
    const role = useRole();

    return [ROLE.PARTNER_TVSRC_MDLG_OWNER, ROLE.PARTNER_TVSRC_MDLG_ADMIN].includes(role.label);
}

export function useIsUserManager() {
    const role = useRole();

    return (
        role.label === ROLE.SITE_ADMIN ||
        role.label === ROLE.PARTNER_OWNER ||
        role.label === ROLE.PARTNER_MODERATOR_OWNER ||
        role.label === ROLE.PARTNER_MODERATOR_ADMIN ||
        role.label === ROLE.PARTNER_TVSRC_MDLG_OWNER ||
        role.label === ROLE.PARTNER_TVSRC_ADMIN ||
        role.label === ROLE.PROVIDER_OWNER || role.label === ROLE.PROVIDER_ADMIN ||
        role.label === ROLE.CONSUMER_OWNER || role.label === ROLE.CONSUMER_ADMIN
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function _toString(value: any, keySeparator?: string): string {
    const sep = keySeparator || '\n';
    if (typeof value === 'number') {
        return value.toString();
    }
    if (typeof value === 'string') {
        return t(value);
    }
    if (Array.isArray(value)) {
        return value.map(v => _toString(v, sep)).join(' ');
    }
    if (typeof value === 'object') {
        return Object.keys(value).map((v) => {
            if (v === 'non_field_errors') {
                return _toString(value[v]);
            } else {
                return `${v}: ${_toString(value[v], sep)}`
            }
        }).join(sep);
    }
    return value?.toString();
}

/**
 * Функция приведения ошибки к человекочитаемому представлению
 * @func extractErrorDetail
 *
 * @param {any}  e   объект ошибки от сервера или XMLHttpRequest.response
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractErrorDetail(e: any): string {
    if (e.data?.response?.detail) {
        return _toString(e.data.response.detail);
    }
    if (e.data?.response?.status?.detail) {
        return _toString(e.data.response.status.detail);
    }
    if (e.data?.response?.status?.msg) {
        return _toString(e.data?.response?.status?.msg);
    }
    if (e.data?.response?.message) {
        return _toString(e.data.response.message);
    }
    if (e.data?.status) {
        return _toString(e.data.status);
    }
    if (e.data?.message) {
        return _toString(e.data.message);
    }
    return e.status || 'ERROR';
}

/**
 * Функция форматирования байтов к их сжатому строковому виду.
 *
 * @param bytes значение в байтах.
 * @param {number=} [decimals=2] - количество значимых цифр после запятой.
 * @param {'ru' | 'en'} [lang='ru'] - язык ответа.
 */
export function formatBytes(bytes: number, decimals: number | undefined = 2, lang: 'ru' | 'en' = 'ru'): string {
    if (!+bytes) return '0 ' + (lang === 'ru' ? 'Байт' : 'Bytes');

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizesEn = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const sizesRu = ['б', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Эб', 'Зб', 'Йб'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${lang === 'ru' ? sizesRu[i] : sizesEn[i]}`;
}

const a: Record<string, string> = { ' ': '_', 'Ё': 'YO', 'Й': 'I', 'Ц': 'TS', 'У': 'U', 'К': 'K', 'Е': 'E', 'Н': 'N', 'Г': 'G', 'Ш': 'SH', 'Щ': 'SCH', 'З': 'Z', 'Х': 'H', 'Ъ': '\'', 'ё': 'yo', 'й': 'i', 'ц': 'ts', 'у': 'u', 'к': 'k', 'е': 'e', 'н': 'n', 'г': 'g', 'ш': 'sh', 'щ': 'sch', 'з': 'z', 'х': 'h', 'ъ': '\'', 'Ф': 'F', 'Ы': 'I', 'В': 'V', 'А': 'A', 'П': 'P', 'Р': 'R', 'О': 'O', 'Л': 'L', 'Д': 'D', 'Ж': 'ZH', 'Э': 'E', 'ф': 'f', 'ы': 'i', 'в': 'v', 'а': 'a', 'п': 'p', 'р': 'r', 'о': 'o', 'л': 'l', 'д': 'd', 'ж': 'zh', 'э': 'e', 'Я': 'Ya', 'Ч': 'CH', 'С': 'S', 'М': 'M', 'И': 'I', 'Т': 'T', 'Ь': '\'', 'Б': 'B', 'Ю': 'YU', 'я': 'ya', 'ч': 'ch', 'с': 's', 'м': 'm', 'и': 'i', 'т': 't', 'ь': '\'', 'б': 'b', 'ю': 'yu' };

export function transliterate(word: string) {
    return word.split('').map(function (char) {
        return a[char] || char;
    }).join('');
}

export function ymHit(url?: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ym(99058005, 'hit', url);
    if (localStorage.authorized) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).ym(99048680, 'hit', url);
    }
}
