import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as Settings } from '../assets/img/icons/settings.svg';
import { ReactComponent as Trash } from '../assets/img/icons/trash.svg';
import { extractErrorDetail, useIsProvider, ymHit } from '../components/common';
import Button from '../components/mplat/Button/Button';
import { CheckBox } from '../components/mplat/CheckBox';
import Content from '../components/mplat/Content';
import ContentShadow from '../components/mplat/ContentShadow';
import { DatePicker } from '../components/mplat/DatePicker';
import Form from '../components/mplat/Form';
import ImageLoader from '../components/mplat/ImageLoader';
import Label from '../components/mplat/Label';
import { Modal } from '../components/mplat/Modal';
import { ErrorModal } from '../components/mplat/Modals/ErrorModal';
import { SuccessModal } from '../components/mplat/Modals/SuccessModal';
import MultiSelect from '../components/mplat/MultiSelect';
import NoHideEmailAdmin from '../components/mplat/NoHide/NoHideEmailAdmin';
import NoHideFIOAdmin from '../components/mplat/NoHide/NoHideFIOAdmin';
import NoHidePhoneAdmin from '../components/mplat/NoHide/NoHidePhoneAdmin';
import { PermissionModalModal } from '../components/mplat/OrderContentConsumer';
import { RadioInput } from '../components/mplat/RadioInput';
import Section from '../components/mplat/Section';
import Select from '../components/mplat/Select';
import TextArea from '../components/mplat/TextArea';
import TextInput from '../components/mplat/TextInput';
import { ACCOUNT_TYPES, SOURCE_MAP } from '../const';
import { useAuth } from '../hooks';
import {
    AccessFull,
    AccessPermissionFull,
    AccessPermissionStatus,
    AccessPermissionUnit,
    LicenseAgreement,
    PermissionUnit,
    SourcesType,
    useCreateAccessPermissionMutation,
    useEditAccessPermissionMutation,
    useLazyGetAccessPermissionQuery,
    useLazyGetAccessRequestQuery,
    useLazyGetPermissionAvailUnitsQuery,
    useLazyGetTVListQuery,
    useLazyGetTVUnitQuery,
    useLazyGetUserBookQuery,
    useUpdateAccessPermissionStatusMutation
} from '../services/content_v1';
import { AccessRequestStatus, useLazyGetAccountListQuery, useLazyGetUserAccountsManageQuery } from '../services/users_v1';
import { toMplatBackendDate, toMplatDate } from './ToolsPage';

export const AVAIL_SOURCES = [
    { value: 1, label: 'Любой' },
    { value: 2, label: 'Спутник' },
    { value: 3, label: 'Медиалогистика' },
    { value: 4, label: 'Другое' },
];

function parseDate(v: string | null | undefined) {
    if (v) {
        try {
            return new Date(v);
        } catch {
            return undefined;
        }
    }
    return undefined;
}

interface ParamDialogModalProps {
    unit?: AccessPermissionUnit,
    isOpen: boolean,
    onClose: () => void,
    onChange: (v: AccessPermissionUnit) => void,
}

export function ParamDialogModal({ unit, isOpen, onClose, onChange }: ParamDialogModalProps) {
    const [version, setVersion] = useState<string>(unit?.version || '');
    const [refetchTV, { data: tvData }] = useLazyGetTVUnitQuery();
    const [fmts, setFmts] = useState<{ id: number; name: string; }[]>([]);

    useEffect(() => {
        if (unit?.unit) {
            refetchTV(unit.unit.id);
            setFmts(unit.fmt);
            setVersion(unit.version || '');
        }
    }, [unit]);

    const availQualities = useMemo(() => {
        if (tvData?.results) {
            const tmp = tvData.results.qualities;
            return tmp;
        }
        return [];
    }, [tvData]);

    return (
        <Modal className={'permission__channels-settings-popup'} isOpen={isOpen} onClose={onClose}>
            <div className="permission__channels-settings">
                <h2>
                    <Trans>order:TV_PARAMS</Trans><br />
                    <a href="" className="link-accent">{unit?.unit.title}</a>
                </h2>
                <h3>
                    <Trans>order:TV_QUALITY</Trans>
                </h3>
                <ul className="form__checkbox-list">
                    {availQualities.map((q) => <li key={q.id}>
                        <CheckBox value={!!fmts.find((f) => f.id === q.id)} label={q.name} onChange={(v) => {
                            setFmts((prev) => {
                                let tmp = [...prev];
                                if (v) {
                                    tmp.push(q);
                                } else {
                                    tmp = tmp.filter((f) => q.id !== f.id);
                                }
                                return tmp;
                            })
                        }} />
                    </li>)}
                </ul>
                <h3><Trans>order:TV_VERSION</Trans></h3>
                <div className="form__item">
                    <TextArea value={version} onChange={(e) => setVersion(e.target.value)} placeholder={t('order:TV_VERSION_PLACEHOLDER')} />
                </div>
                <div className="form__submit">
                    <Button
                        onClick={() => {
                            if (unit) {
                                onChange({ fmt: fmts, version: version, unit: unit?.unit });
                            }
                            onClose();
                        }}
                    >
                        <Trans>Save</Trans>
                    </Button>
                    <Button className="_bd" onClick={onClose}>
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export function toTextStatus(status?: AccessPermissionStatus) {
    switch (status) {
        case AccessPermissionStatus.ISSUED:
            return 'ISSUED';
        case AccessPermissionStatus.WORKING:
            return 'WORKING';
        case AccessPermissionStatus.REVOKED:
            return 'REVOKED';
        default:
            return `STATUS: ${status}`;
    }
}


function PermissionEditorPage() {
    // const
    // hooks
    const auth = useAuth();
    const navigate = useNavigate();
    const { id, ar_id, acc_id } = useParams();
    const isProvider = useIsProvider();
    const [searchParams] = useSearchParams();
    // fetches
    const [refetch, { data: permissionData }] = useLazyGetAccessPermissionQuery();
    const [refetchOrder, { data: orderData }] = useLazyGetAccessRequestQuery();
    const [refetchTV, { data: listTV }] = useLazyGetTVListQuery();
    const [refetchAvailTV, { data: listAvailTV }] = useLazyGetPermissionAvailUnitsQuery();
    const [refetchUsers, { data: users }] = useLazyGetAccountListQuery();
    const [refetchUserBooks, { data: refUserBooks }] = useLazyGetUserBookQuery();
    const [refetchManageAccounts, { data: manageAccs }] = useLazyGetUserAccountsManageQuery();

    const [createPermission] = useCreateAccessPermissionMutation();
    const [updatePermission] = useEditAccessPermissionMutation();
    const [updatePermissionStatus] = useUpdateAccessPermissionStatusMutation();

    // states
    const idAsNumber = Number(id);
    const arIdAsNumber = Number(ar_id);
    const accIdAsNumber = isNaN(Number(acc_id)) ? undefined : Number(acc_id);

    const [fail, setFail] = useState<string>('');
    const [editable, setEditable] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const [view, setView] = useState(false);
    const [tvFilter, setTVFilter] = useState<number | null>(null);
    const [paramIdDialogShow, setParamIdDialogShow] = useState<AccessPermissionUnit | undefined>(undefined);

    const [perm, setPerm] = useState<AccessPermissionFull | undefined>();
    const [order, setOrder] = useState<AccessFull | undefined>();

    const [recipient, setRecipient] = useState<number>(NaN);
    const [licNum, setLicNum] = useState('');
    // const [licDate, setLicDate] = useState('');
    const [licDDate, setLicDDate] = useState<Date | undefined>(undefined);
    const [haveLic, setHaveLic] = useState<boolean>(true);
    const [units, setUnits] = useState<number[]>([]);
    const [issuerAccs, setIssuerAccs] = useState<number[]>([]);
    const [tvDetails, setTVDetails] = useState<AccessPermissionUnit[]>([]);
    // const [source, setSource] = useState<number>(1);
    const [selectAnySource, setSelectAnySource] = useState(false);
    const [selectSatelliteSource, setSelectSatelliteSource] = useState(false);
    const [selectMedialogisticSource, setSelectMedialogisticSource] = useState(true);
    const [selectOtherSource, setSelectOtherSource] = useState(false);
    const [mdlg, setMdlg] = useState(['АО «ЦВКС «МСК-IX»'/*, 'АО «Синтерра Медиа»'*/]);
    const [satellites, setSatellites] = useState<number[]>([]);
    // const [psdetails, setPSDetails] = useState('');
    const [mdlgParams, setMdlgParams] = useState('');
    const [satellitesParams, setSatellitesParams] = useState('');
    const [otherParams, setOtherParams] = useState('');
    const [specInfo, setSpecInfo] = useState('');
    const [permStartDate, setPermStartDate] = useState<Date | undefined>(new Date());
    const [permEndDateType, setPermEndDateType] = useState(1);
    const [permEndDate, setPermEndDate] = useState<Date | undefined>(new Date());
    const [availSat, setAvailSat] = useState<{ value: number; label: string; }[]>([]);

    const availUsers = useMemo(() => {
        if (users?.results.data) {
            return users.results.data.map((u) => ({ value: u.id, label: `${u.name} [${u.id}]` }));
        }
        return [];
    }, [users]);

    const availTVUnits = useMemo(() => {
        if (listAvailTV?.results.data) {
            return listAvailTV?.results.data.reduce((acc, curr) => { acc[curr.id] = curr; return acc; }, {} as Record<number, PermissionUnit>);
        }
        return {};
    }, [listAvailTV]);

    const availUnits = useMemo(() => {
        if (listTV?.results.data) {
            if (order?.units) {
                return listTV.results.data.filter((tv) => availTVUnits[tv.id] !== undefined).filter((tv) => order?.units.find((u) => u.id === tv.id)).map((tv) => ({ value: tv.id, label: tv.title }));
            }
            return listTV.results.data.filter((tv) => availTVUnits[tv.id] !== undefined).map((tv) => ({ value: tv.id, label: tv.title }));
        }
        return [];
    }, [listTV, availTVUnits, order]);

    const mdlgOrgs = useMemo(() => {
        return [
            { value: 'АО «ЦВКС «МСК-IX»', label: 'АО «ЦВКС «МСК-IX»' },
            // { value: 'АО «Синтерра Медиа»', label: 'АО «Синтерра Медиа»' },
        ];
    }, []);

    const sources = useMemo(() => {
        const result: { stype: number, params: string, satellites?: number[] }[] = [];
        if (selectSatelliteSource) {
            result.push({ stype: SourcesType.SATELLITE, params: satellitesParams, satellites: satellites });
        }
        if (selectMedialogisticSource) {
            result.push({ stype: SourcesType.MEDIALOGISTIKA, params: `${mdlg.join(', ')}|${mdlgParams}` });
        }
        if (selectOtherSource) {
            result.push({ stype: SourcesType.OTHER, params: otherParams });
        }
        return result;
    }, [
        selectMedialogisticSource, selectSatelliteSource, selectOtherSource,
        satellites, otherParams, mdlgParams, satellitesParams, mdlg,
    ]);

    const availManageAccs = useMemo(() => {
        return manageAccs?.results.data.map((m) => ({ value: m.id, label: `${m.name} (${m.org?.name})` })) || [];
    }, [manageAccs])

    useEffect(() => {
        setAvailSat(refUserBooks?.results.tvsat.map(g => ({ value: g.id, label: g.name, sys: !g.owner_id })) || []);
    }, [refUserBooks]);

    const availSatellites = useMemo(() => {
        if (listAvailTV?.results.data) {
            const allSatellites = listAvailTV?.results.data
                .filter((tv) => tvDetails.some((tvd) => tvd.unit.id === tv.id))
                .map((tv) => tv.ssources
                    .filter((s) => s.label === 'sourceSatellite')
                    .filter((s) => !!s.satellites))
                .flat()
                .map((s) => s.satellites as unknown as { id: number, name: string }).flat();
            const pureAll = allSatellites.reduce((acc, curr) => { acc[curr.id] = curr; return acc; }, {} as Record<number, { id: number, name: string, }>);
            return Object.values(pureAll).map((s) => ({ value: s.id, label: s.name }));
        }
        return [];
    }, [listAvailTV, tvDetails]);

    // effects
    useEffect(() => ymHit('/access/permissions/'), []);

    useEffect(() => {
        if (listAvailTV?.results.data) {
            const allOthers = listAvailTV?.results.data
                .filter((tv) => tvDetails.some((tvd) => tvd.unit.id === tv.id))
                .map((tv) => tv.ssources
                    .filter((s) => s.label === 'sourceOther')
                    .filter((s) => !!s.params))
                .flat()
                .map((s) => s.params).flat();
            setOtherParams(allOthers.filter((s) => s).join(','));

            const savedIssuerAccs = permissionData?.results.issuer_accs;
            if (!savedIssuerAccs || issuerAccs.length === 0) {
                const rowners = listAvailTV?.results.data
                    .filter((tv) => tvDetails.some((tvd) => tvd.unit.id === tv.id))
                    .map((tv) => tv.rowners)
                    .flat();
                const pureRowners = rowners.reduce((acc, curr) => { acc[curr] = curr; return acc; }, {} as Record<number, number>);
                setIssuerAccs(Object.values(pureRowners));
            }
        }
    }, [listAvailTV, tvDetails, permissionData]);

    useEffect(() => {
        const tmp: Record<string, string> = {};
        searchParams.forEach((v, k) => {
            tmp[k] = v;
        });
        setView(!!tmp.view);
        const tvParam = searchParams.get('tv');
        tvParam && setTVFilter(Number(tvParam));
    }, [searchParams]);

    useEffect(() => {
        tvFilter && setUnits([tvFilter]);
    }, [tvFilter]);

    useEffect(() => {
        if (!isNaN(idAsNumber)) {
            refetch(idAsNumber).unwrap().catch((e) => setFail(extractErrorDetail(e)));
        }
    }, [id, isProvider]);

    useEffect(() => {
        if (isProvider) {
            refetchTV({ offset: 0, limit: -1 });
            refetchAvailTV();
            refetchUsers({ rtype: 2, offset: 0, limit: -1 });
            refetchUserBooks();
            refetchManageAccounts();
        }
    }, [isProvider]);

    useEffect(() => {
        if (!isNaN(arIdAsNumber)) {
            refetchOrder({ ar_id: arIdAsNumber, acc_id: accIdAsNumber || auth.account });
        }
    }, [ar_id]);

    useEffect(() => {
        if (perm) {
            setUnits(perm.units.map((u) => u.unit.id));
        } else {
            if (!tvFilter) {
                setUnits(availUnits.map((u) => u.value));
            } else {
                setUnits([tvFilter]);
            }
        }
    }, [availUnits, tvFilter, perm]);

    useEffect(() => {
        setTVDetails((prev) => {
            const tmp = prev.filter((u) => units.includes(u.unit.id));
            const contains = tmp.map(u => u.unit.id);
            const difference = units.filter(x => !contains.includes(x));
            const tmp2: AccessPermissionUnit[] = listTV?.results.data.filter((u) => units.includes(u.id)).map((u) =>
            ({
                fmt: u.qualities,
                unit: {
                    id: u.id,
                    ctype: u.ctype,
                    licno: u.licno,
                    owner: u.owner.id,
                    title: u.title,
                    mmcertno: u.mmcertno,
                    mmchannel: u.mmchannel,
                    covers: u.covers?.map((c) => c.url) || [],
                    category: u.category,
                    rowners: u.rowners,
                },
                version: '',
            })) || [];

            if (perm?.units) {
                const notContains = ([...tmp2.filter((u) => !perm.units.find((uu) => uu.unit.id === u.unit.id)), ...perm.units]).filter((u) => difference.includes(u.unit.id));
                return [...tmp, ...notContains];
            }

            const notContains = (tmp2).filter((u) => difference.includes(u.unit.id));
            return [...tmp, ...notContains];
        });
    }, [units, perm]);

    useEffect(() => {
        if (permissionData?.results) {
            const permSources = permissionData.results.details.sources;
            if (permSources) {
                const sAny = permSources.some((s) => s.stype === SourcesType.ANY);
                if (sAny) {
                    setSelectAnySource(sAny);
                    setSelectMedialogisticSource(false);
                    setSelectSatelliteSource(false);
                    setSelectOtherSource(false);
                } else {
                    const sMdlg = permSources.find((s) => s.stype === SourcesType.MEDIALOGISTIKA);
                    if (sMdlg) {
                        setSelectMedialogisticSource(true);
                        const params = sMdlg.params?.split('|') || [''];
                        if (params.length > 1) {
                            setMdlgParams(params[1]);
                            setMdlg(params[0].split(',').map((v) => v.trim()));
                        } else {
                            setMdlgParams(params[0]);
                        }
                    }
                    const sSat = permSources.find((s) => s.stype === SourcesType.SATELLITE);
                    if (sSat) {
                        setSelectSatelliteSource(true);
                        setSatellites(sSat.satellites?.map((s) => s.id) || []);
                        setSatellitesParams(sSat.params || '');
                    }
                    const sOther = permSources.find((s) => s.stype === SourcesType.OTHER);
                    if (sOther) {
                        setSelectOtherSource(true);
                        setSatellitesParams(sOther.params || '');
                    }
                }
            }

            setPerm(permissionData.results);
            setLicNum(permissionData.results.details.licno || '');
            setLicDDate(parseDate(permissionData.results.details.licdate));
            setHaveLic(!!permissionData.results.details.licno || false);
            setRecipient(permissionData.results.recipient.id);
            // setSource(permissionData.results.details.sources?.at(0)?.stype || 1);
            // setPSDetails(permissionData.results.details.sources?.at(0)?.params || '');
            setSpecInfo(permissionData.results.details.additional || '');
            setPermStartDate(parseDate(permissionData.results.validity.lower));
            setPermEndDateType(!permissionData.results.validity.upper ? 1 : 2);
            setPermEndDate(parseDate(permissionData.results.validity.upper));
            setIssuerAccs(permissionData.results.issuer_accs?.map((a) => a.id) || []);
            if (permissionData.results.accessrequest?.id) {
                refetchOrder({ ar_id: permissionData.results.accessrequest?.id, acc_id: accIdAsNumber || auth.account });
            }
            setEditable(permissionData.results.status !== AccessPermissionStatus.ISSUED && permissionData.results.status !== AccessPermissionStatus.REVOKED);
        }
    }, [permissionData]);

    useEffect(() => {
        if (orderData?.results) {
            setOrder(orderData.results);
            setRecipient(orderData.results.consumer.id);
        }
    }, [orderData]);

    // if (fail) {
    //     return (
    //         <Content>
    //             <ContentShadow />
    //             <div className="permission">
    //                 <h1 className="permission__heading">
    //                     {fail}
    //                 </h1>
    //             </div>
    //         </Content>
    //     );
    // }

    if (!isProvider || view) {
        return (
            <>
                {perm?.id &&
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    <PermissionModalModal isOpen={true} permId={perm?.id || NaN} onClose={() => navigate('/access/permissions')} />
                }
            </>
        );
    }

    return (
        <Content>
            <ContentShadow />
            <ParamDialogModal
                isOpen={paramIdDialogShow !== undefined}
                unit={paramIdDialogShow}
                onClose={() => setParamIdDialogShow(undefined)}
                onChange={(modUnit) => {
                    setTVDetails((prev) => {
                        const tmp = [...prev];
                        const index = prev.findIndex((u) => u.unit.id === modUnit.unit.id);
                        tmp.splice(index, 1, modUnit);
                        return tmp;
                    })
                }}
            />
            <ErrorModal title={t('Permission')} content={fail} isOpen={!!fail} onClose={() => setFail('')} />
            <SuccessModal title={t('order:PERMISSION_SUCCESS_MSG')} content={''} isOpen={!!showSuccess} onClose={() => setShowSuccess(false)} />

            <div className="permission">
                <h1 className="permission__heading">
                    {perm?.status === AccessPermissionStatus.ISSUED ?
                        <Trans>Permission</Trans>
                        :
                        perm?.status === AccessPermissionStatus.REVOKED ?
                            <span style={{ color: 'red' }}><Trans>Permission</Trans></span>
                            :
                            <Trans>order:PERMISSION_REG</Trans>
                    }
                </h1>
                {perm &&
                    <div className="user-settings__labels">
                        <span className="user-settings__labels-item">{perm?.issuer.nickname || perm?.issuer.id}.{perm?.id}.{perm?.recipient.nickname || perm?.recipient.id}.request</span>
                        <span className="user-settings__labels-item">{t(toTextStatus(perm?.status))}</span>
                    </div>
                }
                <div className="permission__body">
                    <div className="permission__nav">
                        {perm &&
                            <ul className="content-add__nav">
                                {isProvider && perm?.status === AccessPermissionStatus.ISSUED &&
                                    <li>
                                        <Link to="?view=true">
                                            <Trans>order:VIEW_AS_CONSUMER</Trans>
                                        </Link>
                                    </li>
                                }
                                {perm?.status === AccessPermissionStatus.ISSUED &&
                                    <li>
                                        <Link to="" className="_remove" onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            const ret = confirm(t('order:CONFIRM_MESSAGE'));
                                            if (ret) {
                                                updatePermissionStatus({
                                                    id: idAsNumber,
                                                    status: AccessPermissionStatus.REVOKED,
                                                    // updated: new Date().toISOString(),
                                                }).unwrap().then(() => {
                                                    // setSuccess(true);
                                                }).catch(e => {
                                                    setFail(extractErrorDetail(e));
                                                });
                                            }
                                        }}>
                                            <Trans>Reject</Trans>
                                        </Link>
                                    </li>
                                }
                            </ul>
                        }
                    </div>
                    <Form className="permission__main">
                        {order &&
                            <Section
                                className="form__section permission__order"
                                label={<>
                                    {t('order:ORDER_FROM')}&nbsp;«{order.consumer.name}»&nbsp;
                                    {order.additional?.sendby && <span style={{ color: 'red' }}>
                                        {order.additional?.sendby.status === AccessRequestStatus.COPIED && t('order:ORDER_COPIED_FROM')}
                                        {order.additional?.sendby.status === AccessRequestStatus.FORWARDED && t('order:ORDER_FORVARDED_FROM')}
                                        {order.additional.sendby.account.org.name || order.additional.sendby.account.name}
                                    </span>}
                                </>}
                            >
                                <div className="permission__order-body">
                                    <div className="permission__order-item">
                                        <h4><Trans>order:CONSUMER_ACCOUNT</Trans></h4>
                                        <p>{order?.consumer.name}</p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4><Trans>Organization</Trans></h4>
                                        <p>{order?.consumer.org.name}</p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4><Trans>order:ORDER_DATE</Trans></h4>
                                        <p>{toMplatDate(order?.added, true)}</p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4><Trans>order:ORDER_SOURCE</Trans></h4>
                                        <p>{SOURCE_MAP[order?.details.pstype]}</p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4><Trans>order:ORDER_LIC</Trans></h4>
                                        <p>
                                            {order?.details.licagr === LicenseAgreement.YES && t('Yes')}
                                            {order?.details.licagr === LicenseAgreement.NO && t('No')}
                                            {order?.details.licagr === LicenseAgreement.PARTIAL && t('order:ORDER_LIC_PARTIAL')}
                                        </p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4><Trans>order:REQ_TEST_PERIOD</Trans></h4>
                                        <p>{order.details.ontest ? 'Да' : 'Нет'}</p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4><Trans>order:ORDER_REQ_WHO</Trans></h4>
                                        <p>{t(ACCOUNT_TYPES[order.owner.rtype])}</p>
                                        <p>
                                            <NoHideFIOAdmin user={order.owner.owner} />,
                                            <NoHidePhoneAdmin user={order.owner.owner} />,
                                            <br />
                                            <NoHideEmailAdmin user={order.owner.owner} />
                                        </p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4><Trans>order:ORDER_OWNER</Trans></h4>
                                        <p>
                                            <NoHideFIOAdmin user={order.owner.owner} />,
                                            <NoHidePhoneAdmin user={order.owner.owner} />,
                                            <br />
                                            <NoHideEmailAdmin user={order.owner.owner} />
                                        </p>
                                    </div>
                                    <div className="permission__order-item">
                                        <h4>
                                            <Trans>order:ORDER_WISHES</Trans>
                                        </h4>
                                        <TextArea disabled value={order.details.additional} placeholder={t('order:ORDER_WISHES')} />
                                    </div>
                                </div>
                            </Section>
                        }
                        <Section className="form__section" label={t('order:TV_PERM_RECIPIENT')}>
                            <Select value={recipient} disabled={!editable} options={availUsers} onChange={(v) => setRecipient(v)} />
                        </Section>

                        <Section className="form__section permission__channels" label={t('account:SPEC_TV')}>
                            <MultiSelect value={units} disabled={!editable} options={availUnits} setValue={(v) => setUnits(v)} />

                            <div className="permission__channels-list">
                                <table>
                                    <tbody>
                                        {tvDetails.map((u) => <tr key={u.unit.id}>
                                            <td className="_name">
                                                <div className="tv-content__order-list-item">
                                                    {u.unit.covers.length > 0 && <ImageLoader url={u.unit.covers[0]} />}
                                                    <span className="tv-content__order-list-item-name">{u.unit.title}</span>
                                                    <span className="tv-content__order-list-item-theme">{u.unit.category?.name}</span>
                                                </div>
                                            </td>
                                            <td className="_quality">{u.fmt.map((f) => f.name).join(', ')}</td>
                                            <td className="_comment">{u.version}</td>
                                            <td className="_settings">
                                                {editable &&
                                                    <Button
                                                        className="permission__channels-list-btn"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setParamIdDialogShow(u);
                                                        }}
                                                    >
                                                        <Settings />
                                                    </Button>}
                                            </td>
                                            <td className="_remove">
                                                {editable && <Button
                                                    className="permission__channels-list-btn"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setUnits((prev) => prev.filter((uu) => u.unit.id !== uu));
                                                    }}
                                                >
                                                    <Trash />
                                                </Button>}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </Section>

                        {availManageAccs.length > 1 && (
                            <Section className="form__section" label={t('account:ROWNERS')}>
                                <MultiSelect value={issuerAccs} disabled={!editable} options={availManageAccs} setValue={(v) => setIssuerAccs(v)} />
                            </Section>
                        )}

                        <Section className="form__section permission__sources" label={t('order:AVAIL_SOURCE')}>
                            <div className="form__item">
                                <CheckBox disabled={!editable} value={selectAnySource} onChange={(v) => {
                                    if (v) {
                                        setSelectAnySource(true);
                                        setSelectMedialogisticSource(false);
                                        setSelectSatelliteSource(false);
                                        setSelectOtherSource(false);
                                    }
                                }} label={t('tv:ANY_SOURCE')} />
                            </div>
                            <div className="form__item">
                                <CheckBox disabled={!editable} value={selectMedialogisticSource} onChange={(v) => {
                                    if (v) {
                                        setSelectAnySource(false);
                                    } else {
                                        if (!selectSatelliteSource && !selectOtherSource) {
                                            setSelectAnySource(true);
                                        }
                                    }
                                    setSelectMedialogisticSource(v);
                                }} label={t('tv:MEDIALOGISTIC')} />
                            </div>
                            {selectMedialogisticSource && <div className="form__item">
                                <MultiSelect disabled={!editable} value={mdlg} setValue={(e) => setMdlg((p) => e.length ? e : [...p])} options={mdlgOrgs} />
                                <TextInput disabled={!editable} value={mdlgParams} placeholder={t('order:PARAMS_PLACEHOLDER')} onChange={(e) => setMdlgParams(e.target.value)} />
                            </div>}
                            <div className="form__item">
                                <CheckBox disabled={!editable} value={selectSatelliteSource} onChange={(v) => {
                                    if (v) {
                                        setSelectAnySource(false);
                                        setSatellites(availSatellites.map((s) => s.value));
                                    } else {
                                        setSatellites([]);
                                        if (!selectMedialogisticSource && !selectOtherSource) {
                                            setSelectAnySource(true);
                                        }
                                    }
                                    setSelectSatelliteSource(v);
                                }} label={t('tv:SATELLITE')} />
                            </div>
                            {selectSatelliteSource && <div className="form__item">
                                <MultiSelect disabled={!editable} value={satellites} setValue={(e) => setSatellites(e)} options={availSat} />
                                <TextInput disabled={!editable} value={satellitesParams} placeholder={t('order:PARAMS_PLACEHOLDER')} onChange={(e) => setSatellitesParams(e.target.value)} />
                            </div>}
                            <div className="form__item">
                                <CheckBox disabled={!editable} value={selectOtherSource} onChange={(v) => {
                                    if (v) {
                                        setSelectAnySource(false);
                                    } else {
                                        if (!selectMedialogisticSource && !selectSatelliteSource) {
                                            setSelectAnySource(true);
                                        }
                                    }
                                    setSelectOtherSource(v);
                                }} label={t('tv:OTHER_SOURCE')} />
                            </div>
                            {selectOtherSource && <div className="form__item">
                                <TextInput disabled={!editable} value={otherParams} placeholder={t('order:PARAMS_PLACEHOLDER')} onChange={(e) => setOtherParams(e.target.value)} />
                            </div>}

                            {/* <RadioInput disabled={!editable} value={source} items={AVAIL_SOURCES} onChange={(v) => setSource(v)} />
                            {source === 4 && <>
                                <TextInput disabled={!editable} value={psdetails} placeholder={t('order:PARAMS_PLACEHOLDER')} onChange={(e) => setPSDetails(e.target.value)} />
                            </>} */}
                        </Section>

                        <Section className="form__section permission__sources" label={t('order:ORDER_LIC')}>
                            <RadioInput
                                disabled={!editable}
                                value={haveLic}
                                items={[
                                    { value: true, label: t('order:ORDER_LIC_WITH') },
                                    { value: false, label: t('order:ORDER_LIC_WITHOUT') }
                                ]}
                                onChange={(v) => setHaveLic(v)}
                            />
                            {haveLic && <>
                                <TextInput disabled={!editable} value={licNum} placeholder={t('order:ORDER_LIC_NO')} onChange={(e) => setLicNum(e.target.value)} />
                                {/* <TextInput value={licDate} placeholder={t('order:ORDER_LIC_DATE')} onChange={(e) => setLicDate(e.target.value)} /> */}
                                <DatePicker handleEdit disabled={!editable} value={licDDate} simple fullSize placeholder={t('order:ORDER_LIC_DATE')} setValue={(v) => setLicDDate(v)} />
                            </>}
                        </Section>

                        <Section label={t('order:SPEC_CONDITIONS')} >
                            <TextArea disabled={!editable} value={specInfo} placeholder={t('order:SPEC_CONDITIONS_PLACEHOLDER')} onChange={(e) => setSpecInfo(e.target.value)} />
                        </Section>
                        <Section className="form__section permission__dates" label={t('order:PERMISSION_PERIOD')}>
                            <div className="form__item ">
                                <Label>
                                    <Trans>order:PERMISSION_PERIOD_START</Trans>
                                </Label>
                                <DatePicker handleEdit disabled={!editable} value={permStartDate} simple fullSize setValue={(v) => setPermStartDate(v)} placeholder={t('order:PERMISSION_PERIOD_START')} />
                            </div>
                            <div className="form__item">
                                <Label>
                                    <Trans>order:PERMISSION_PERIOD_END</Trans>
                                </Label>
                                <Select
                                    disabled={!editable}
                                    className="permission__dates-end"
                                    value={permEndDateType}
                                    options={[{ value: 1, label: t('order:UNTIL_REVOKE') }, { value: 2, label: t('order:UNTIL_DATE') }]}
                                    onChange={(v) => setPermEndDateType(v)}
                                    nosearch
                                    placeholder={t('order:PERMISSION_PERIOD_END')}
                                >
                                    {permEndDateType === 2 &&
                                        <DatePicker
                                            disabled={!editable}
                                            handleEdit
                                            className="_show"
                                            value={permEndDate}
                                            simple
                                            fullSize
                                            setValue={(v) => setPermEndDate(v)}
                                            minDate={new Date()}
                                            placeholder={t('order:PERMISSION_PERIOD_END')}
                                        />}
                                </Select>
                            </div>
                        </Section>

                        <div className="form__submit permission__submit">
                            {perm?.status === AccessPermissionStatus.WORKING &&
                                <Button
                                    disabled={!perm?.id}
                                    onClick={() => {
                                        updatePermissionStatus({
                                            id: idAsNumber,
                                            status: AccessPermissionStatus.ISSUED,
                                            // updated: new Date().toISOString(),
                                        }).unwrap().then(() => {
                                            setShowSuccess(true);
                                            // setSuccess(true);
                                        }).catch(e => {
                                            setFail(extractErrorDetail(e));
                                        })
                                    }}
                                >
                                    <Trans>Send</Trans>
                                </Button>
                            }
                            {(!perm || perm.status === AccessPermissionStatus.WORKING) &&
                                <Button
                                    disabled={tvDetails.length === 0}
                                    className="_bd"
                                    onClick={() => {
                                        if (perm?.id) {
                                            updatePermission({
                                                id: perm.id,
                                                issuer: auth.account,
                                                recipient: recipient,
                                                validity: {
                                                    lower: toMplatBackendDate(permStartDate, true),
                                                    upper: permEndDateType === 1 ? undefined : toMplatBackendDate(permEndDate, true),
                                                },
                                                details: {
                                                    havelic: haveLic,
                                                    licno: licNum,
                                                    licdate: toMplatBackendDate(licDDate, true) || null,
                                                    ontest: null,
                                                    sources: selectAnySource ? [{ stype: SourcesType.ANY }] : sources,
                                                    additional: specInfo,
                                                },
                                                units: tvDetails.map((u) => ({ fmt: u.fmt.map((f) => f.id), unit: u.unit.id, version: u.version })),
                                                accessrequest: order ? { id: order.id, account_id: order.account_id } : undefined,
                                                accs: issuerAccs.length > 0 ? issuerAccs : undefined,
                                            }).unwrap().then((ret) => {
                                                navigate(`/access/permissions/${ret.results.id}`, { replace: true });
                                            }).catch(e => {
                                                setFail(extractErrorDetail(e));
                                            });
                                        } else {
                                            createPermission({
                                                issuer: auth.account,
                                                recipient: recipient,
                                                validity: {
                                                    lower: toMplatBackendDate(permStartDate, true),
                                                    upper: permEndDateType === 1 ? undefined : toMplatBackendDate(permEndDate, true),
                                                },
                                                details: {
                                                    havelic: haveLic,
                                                    licno: licNum,
                                                    licdate: toMplatBackendDate(licDDate, true) || null,
                                                    ontest: null,
                                                    sources: selectAnySource ? [{ stype: SourcesType.ANY }] : sources,
                                                    additional: specInfo,
                                                },
                                                units: tvDetails.map((u) => ({ fmt: u.fmt.map((f) => f.id), unit: u.unit.id, version: u.version })),
                                                accessrequest: order ? { id: order.id, account_id: order.account_id } : undefined,
                                                accs: issuerAccs.length > 0 ? issuerAccs : undefined,
                                            }).unwrap().then((ret) => {
                                                navigate(`/access/permissions/${ret.results.id}`, { replace: true });
                                                // setSuccess(true);
                                            }).catch(e => {
                                                setFail(extractErrorDetail(e));
                                            });
                                        }

                                    }}
                                >
                                    <Trans>Save</Trans>
                                </Button>
                            }
                        </div>
                    </Form>
                </div>
            </div>
        </Content>
    );
}

export default PermissionEditorPage;
